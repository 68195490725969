<template>
  <div class="semantic">
    <!-- <PdfVisor pdf="/docs/MyFans1A.com DMCA Policy.pdf" /> -->
    <div v-html="loadHTML('/docs/MyFans1A.com DMCA Policy.html')"></div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "DMCA - My Fans 1A",
  },
  components: {
    PdfVisor: () => import("../widgets/PdfVisor.vue"),
  },
};
</script>
